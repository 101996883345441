import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LinearProgress from '@material-ui/core/LinearProgress';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import tableIcons from '../tableIcons';
import MaterialTable from 'material-table';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var _ = require('lodash');
var moment = require('moment');

// let apiUrl = "http://localhost:3020";
let apiUrl = "http://notes.tigerex.net";

export default class Landing extends Component {

  state = {
    nanoStreams: [],
    futureOrders: [],
    spotOrders: [],
    bottomInfo: {
      futurePrice: 0,
      indexPrice: 0,
      currentSpread: 0,
    },
    startTime: Date.now(),
    latency: 0,
    open: false,
    addStream: false,
    minExitSpread: '',
    minEnterSpread: '',
    spotCashLimit: '',
    futureContractLimit: '0',
    // entrySpotLimit: '',
    entryContractLimit: '',
    platform: 'Binance',
    coin: 'BTC',
    contract: '1',
    exitSpotLimit: '',
    isError: false,
    marketOrderStream: false,
    bitcoinShort: 0,
    bitcoinLong: 0,
    contractShort: 0,
    contractLong: 0,
    orderDispency: 0,
    dollarDispency: 0,
    netDollarEarned: 0,
    capitalInvestment: 0,
    bitcoinPruchased: 0,
    profitMargin: 0,
    expiryDate: 0,
    annulised: 0,
    futureMarket: '',
    spotMarket: '',
  }

  componentDidMount() {
    this.connectSocket();
  }

  connectSocket = ()  =>  {
    const ws = new WebSocket('ws://notes.tigerex.net:4021');
    // const ws = new WebSocket('ws://localhost:4021');
    ws.onopen = () => {
      console.log('connected');
    }
  
    ws.onmessage = (evnt) => {
      let data = JSON.parse(evnt.data);
      if (data.event === 'bottom_info') {
        let latency = Date.now() - this.state.startTime;
        this.setState({
          bitcoinShort: data.info.bitcoinShort,
          bitcoinLong: data.info.bitcoinLong,
          contractShort: data.info.contractShort,
          contractLong: data.info.contractLong,
          orderDispency: data.info.orderDispency,
          dollarDispency: data.info.dollarDispency,
          netDollarEarned: data.info.netDollarEarned,
          capitalInvestment: data.info.capitalInvestment,
          bitcoinPruchased: data.info.bitcoinPruchased,
          profitMargin: data.info.profitMargin,
          expiryDate: data.info.expiryDate,
          annulised: data.info.annulised,
          latency: (latency/1000).toFixed(2),
          bottomInfo: data.info,
          open: true,
          startTime: Date.now(),
        })
      }
      
      if (data.event === 'stream') {
        this.setState({
          nanoStreams: _.isEmpty(data.createdStream) ? [] : [data.createdStream],
          marketOrderStream: data.marketOrderStream,
        })
      }
      
      if (data.event === 'order') {
        this.setState({
          spotOrders: data.spotOrders,
          futureOrders: data.futureOrders,
        });
      }
    }
    
    ws.onclose = () => {
      this.setState({open: false});
    }
  }

  createStream = () => {
    let {
      spotMarket,
      futureMarket,
      minExitSpread,
      minEnterSpread,
      spotCashLimit,
      futureContractLimit,
      // entrySpotLimit,
      entryContractLimit,
      platform,
      coin,
      contract,
      exitSpotLimit,
    } = this.state;

    if (
      minEnterSpread && minExitSpread && spotCashLimit && futureContractLimit &&
      entryContractLimit && coin && contract && exitSpotLimit && spotMarket && futureMarket &&
      platform
    ) {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            spotMarket: this.state.spotMarket,
            futureMarket: this.state.futureMarket,
            minExitSpread: this.state.minExitSpread,
            minEnterSpread: this.state.minEnterSpread,
            spotCashLimit: this.state.spotCashLimit,
            futureContractLimit: this.state.futureContractLimit,
            // entrySpotLimit: this.state.entrySpotLimit,
            entryContractLimit: this.state.entryContractLimit,
            coin: this.state.coin,
            contract: this.state.contract,
            exitSpotLimit: this.state.exitSpotLimit,
          })
      };
  
      fetch(`${apiUrl}/binance/create_market_stream`, requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              addStream: false,
              // items: result.items
            });
          },
          (error) => {
            this.setState({
              addStream: false,
              // error
            });
          }
        );
    } else {
      this.setState({
        isError: true,
      });
    }

  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
    console.log(event.target.value);
  }

  addStartStream = () => {
    this.setState({addStream: false});
  };

  clearStartStream = () => {
    fetch(`${apiUrl}/binance/clear_market_stream`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            addStream: false,
            nanoStreams: [],
            // items: result.items
          });
        },
        (error) => {
          this.setState({
            addStream: false,
            nanoStreams: [],
            // error
          });
        }
      );
  };

  runStream = () => {
    fetch(`${apiUrl}/binance/start_market_stream`)
      .then(res => res.json())
      .then(
        (result) => {
          //
        },
        (error) => {
          //
        }
      );
  }

  stopStream = () => {
    fetch(`${apiUrl}/binance/stop_market_stream`)
      .then(res => res.json())
      .then(
        (result) => {
          //
        },
        (error) => {
          //
        }
      );
  }

  render() {
    const classes = useStyles;
    const {
      nanoStreams,
      addStream,
      spotOrders,
      futureOrders
    } = this.state;
    var w = window.innerWidth;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" color="default">
          <Toolbar>
            <Typography variant="h6" style={{flexGrow: 1}}>
              Nanonick
            </Typography>
            {/* <Fab size="small" color={nanoStreams.length > 0 ? "secondary" : "primary"} aria-label="add" style={{
                position: 'absolute',
                bottom: 'auto',
                right: w * 0.07,
              }} onClick={this.state.marketOrderStream ? this.stopStream : this.runStream}>
              {this.state.marketOrderStream ? <StopIcon /> : <PlayArrowIcon/>}
            </Fab> */}
            <Fab size="small" color={nanoStreams.length > 0 ? "secondary" : "primary"} aria-label="add" style={{
              position: 'absolute',
              bottom: 'auto',
              right: w * 0.03,
            }} onClick={() => {
              if (nanoStreams.length > 0) {
                this.clearStartStream();
              } else {
                this.setState({addStream: true});
              }
            }}>
              {nanoStreams.length ? <RemoveIcon /> : <AddIcon />}
            </Fab>
          </Toolbar>
        </AppBar>
        <Container fixed={true} style={{minHeight: '80vh'}}>
        <BottomNavigation
          onChange={(event, newValue) => {
            // this.setState({

            // });
          }}
          showLabels
          style={{marginTop: 70, flexGrow: 1,}}
        >
          <BottomNavigationAction label={`Latency`} icon={<Typography >{`${this.state.latency} ms`}</Typography>} />
          <BottomNavigationAction label={this.state.open ? 'Connected' : 'Connecting...'} icon={this.state.open ? <WifiIcon color="primary" /> : <WifiOffIcon color="error" /> } />
          <BottomNavigationAction label={`Index Price ${this.state.bottomInfo.indexMarket}`} icon={<Typography >{this.state.bottomInfo.indexPrice}</Typography>} />
          <BottomNavigationAction label={`Current Spread`} icon={<Typography >{parseFloat(this.state.bottomInfo.currentSpread).toFixed(4)}%</Typography>} />
          <BottomNavigationAction label={`Binance Price`} icon={<Typography >{this.state.bottomInfo.futurePrice}</Typography>} />
          <BottomNavigationAction label={`Kraken Future`} icon={<Typography >{this.state.bottomInfo.futureKrakenPrice}</Typography>} />
          <BottomNavigationAction label={`Bitmax Future`} icon={<Typography >{this.state.bottomInfo.futureBitmexPrice}</Typography>} />
          <BottomNavigationAction label={`Deribit Future`} icon={<Typography >{this.state.bottomInfo.futureDeribitPrice}</Typography>} />
        </BottomNavigation>
          <Grid container direction="column" className={classes.root} spacing={4}>
            <Grid item xs={12} style={{paddingTop: 20}}>
              <TableContainer component={Paper}>
                {/* <Toolbar>
                  <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                      Scripts
                    </Typography>
                </Toolbar> */}
                <Table size="small" aria-label="purchases" style={{position: 'relative', overflowX: 'auto'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Exchange</TableCell>
                      <TableCell align="right">Minimum Enter Spread</TableCell>
                      <TableCell align="right">Minimum Exit Spread</TableCell>
                      <TableCell align="right">Spot Cash Limit (USD/USDT)</TableCell>
                      {/* <TableCell align="right">Entry Spot Coin (BTC)</TableCell> */}
                      <TableCell align="right">Entry Crypto Value</TableCell>
                      {/* <TableCell align="right">Future Contract Limit (Contract)</TableCell> */}
                      <TableCell align="right">Coin (BTC, ETH)</TableCell>
                      <TableCell align="right">Contract Type (0 = monthly, 1 = quaterly)</TableCell>
                      <TableCell align="right">Days</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nanoStreams.map((nanoStream, index) => (
                      <TableRow key={index + 1}>
                        <TableCell align="right">{nanoStream.exchange}</TableCell>
                        <TableCell align="right">{nanoStream.minExitSpread}</TableCell>
                        <TableCell align="right">{nanoStream.minEnterSpread}</TableCell>
                        <TableCell align="right">{nanoStream.spotCashLimit}</TableCell>
                        {/* <TableCell align="right">{nanoStream.entrySpotLimit}</TableCell> */}
                        <TableCell align="right">{nanoStream.entryContractLimit}</TableCell>
                        {/* <TableCell align="right">{nanoStream.futureContractLimit}</TableCell> */}
                        <TableCell align="right">{nanoStream.coin}</TableCell>
                        <TableCell align="right">{nanoStream.contract}</TableCell>
                        <TableCell align="right">{nanoStream.days}</TableCell>
                        <TableCell align="right">{nanoStream.startScript ? <Button onClick={this.stopStream} color="secondary">Stop</Button> : <Button onClick={this.runStream} color="primary">Start</Button>}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <ExcelFile element={<Button variant="contained" color="primary">Export</Button>}>
                  <ExcelSheet data={spotOrders} name="Spot Orders">
                      <ExcelColumn label="Symbol" value="symbol"/>
                      <ExcelColumn label="Order Type" value="side"/>
                      <ExcelColumn label="Amount" value="amount"/>
                      <ExcelColumn label="Price" value="price"/>
                      <ExcelColumn label="Cost" value="cost"/>
                      <ExcelColumn label="Fee" value="fee"/>
                      <ExcelColumn label="Final Cost" value="finalCost"/>
                      <ExcelColumn label="Date" value="transactTime"/>
                  </ExcelSheet>
                  <ExcelSheet data={futureOrders} name="Future orders">
                  <ExcelColumn label="Symbol" value="symbol"/>
                      <ExcelColumn label="Order Type" value="side"/>
                      <ExcelColumn label="Contract" value="origQty"/>
                      <ExcelColumn label="Price" value="price"/>
                      <ExcelColumn label="Cost" value="cost"/>
                      <ExcelColumn label="Fee" value="fee"/>
                      <ExcelColumn label="Final Cost" value="finalCost"/>
                      <ExcelColumn label="Date" value="transactTime"/>
                  </ExcelSheet>
              </ExcelFile>
            </Grid>
            {/* <Grid item xs={12}>
              <BottomNavigation
                onChange={(event, newValue) => {
                  // this.setState({

                  // });
                }}
                showLabels
                style={{marginTop: 1, flexGrow: 1,}}
              >
                <BottomNavigationAction label={`Bitcoin Short`} icon={<Typography >{`${parseFloat(this.state.bitcoinShort).toFixed(8)}`}</Typography>} />
                <BottomNavigationAction label={`Bitcoin Long`} icon={<Typography >{parseFloat(this.state.bitcoinLong).toFixed(8)}</Typography>} />
                <BottomNavigationAction label={`Contract Short`} icon={<Typography >{this.state.contractShort}</Typography>} />
                <BottomNavigationAction label={`Contract Long`} icon={<Typography >{this.state.contractLong}</Typography>} />
                <BottomNavigationAction label={`Order Dispency`} icon={<Typography >{parseFloat(this.state.orderDispency).toFixed(4)}</Typography>} />
                <BottomNavigationAction label={`Dollar Dispency`} icon={<Typography >{parseFloat(this.state.dollarDispency).toFixed(4)}</Typography>} />
                <BottomNavigationAction label={`Net Dollar Earned`} icon={<Typography >{this.state.netDollarEarned}</Typography>} />
                <BottomNavigationAction label={`Capital Investment`} icon={<Typography >{this.state.capitalInvestment}</Typography>} />
                <BottomNavigationAction label={`Bitcoin Purchased`} icon={<Typography >{this.state.bitcoinPruchased}</Typography>} />
                <BottomNavigationAction label={`Profit Margin`} icon={<Typography >{this.state.profitMargin}</Typography>} />
                <BottomNavigationAction label={`Expiry Date`} icon={<Typography >{this.state.expiryDate}</Typography>} />
                <BottomNavigationAction label={`Annulised`} icon={<Typography >{this.state.annulised}</Typography>} />
              </BottomNavigation>
            </Grid> */}

            <Grid item xs={12}>
              {nanoStreams.length > 0 ? nanoStreams[0].startScript ? <Typography color="secondary" >Do Not Press Back or Exit The Page</Typography> : <div/> : <div/>}
              {nanoStreams.length > 0 ? nanoStreams[0].startScript ? <LinearProgress color="secondary" /> : <div/> : <div/>}
              <TableContainer component={Paper}>
                {/* <Toolbar>
                  <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                      Future Orders
                    </Typography>
                </Toolbar> */}
                <MaterialTable
                    title="Spot Orders"
                    options={{
                        exportAllData: true,
                        actionsColumnIndex: -1,
                        search: false,
                        showFirstLastPageButtons: false,
                        padding: 'dense',
                        pageSize: 10,
                        exportButton: true,
                    }}
                    icons={tableIcons}
                    columns={[
                      {
                          title: 'Symbol',
                          field: 'symbol',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Order Type',
                          field: 'side',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Amount',
                          field: 'amount',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Price',
                          field: 'price',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Cost',
                          field: 'cost',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Fees (%)',
                          field: 'fee',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Final Cost',
                          field: 'finalCost',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Date',
                          field: 'transactTime',
                          sorting: true,
                          filtering: false,
                      },
                  ]}
                  data={spotOrders}
                />
                {/* <Table size="small" aria-label="purchases">
                  <TableHead> 
                    <TableRow>
                      <TableCell align="right">Symbol</TableCell>
                      <TableCell align="right">Order Type</TableCell>
                      <TableCell align="right">Contract</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Cost</TableCell>
                      <TableCell align="right">Fees (%)</TableCell>
                      <TableCell align="right">Final Cost</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spotOrders.map((spotOrder, index) => (
                      <TableRow key={`${index}`}>
                        <TableCell align="right">{spotOrder.symbol}</TableCell>
                        <TableCell align="right">{spotOrder.side}</TableCell>
                        <TableCell align="right">{spotOrder.amount}</TableCell>
                        <TableCell align="right">{spotOrder.price}</TableCell>
                        <TableCell align="right">{spotOrder.cost}</TableCell>
                        <TableCell align="right">{spotOrder.fee}</TableCell>
                        <TableCell align="right">{spotOrder.finalCost}</TableCell>
                        <TableCell align="right">{dateFormat(spotOrder.transactTime, "mm/dd/yyyy h:MM:ss TT")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Toolbar>
                  <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                      Future Orders
                    </Typography>
                </Toolbar>
                <MaterialTable
                    title="Future Orders"
                    options={{
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      search: false,
                      showFirstLastPageButtons: false,
                      padding: 'dense',
                      pageSize: 10,
                      exportButton: true,
                    }}
                    icons={tableIcons}
                    columns={[
                      {
                          title: 'Symbol',
                          field: 'symbol',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Order Type',
                          field: 'side',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Contract',
                          field: 'origQty',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Price',
                          field: 'price',
                          filtering: false,
                          sorting: true,
                      },
                      {
                          title: 'Cost',
                          field: 'cost',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Fees (%)',
                          field: 'fee',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Final Cost',
                          field: 'finalCost',
                          sorting: true,
                          filtering: false,
                      },
                      {
                          title: 'Date',
                          field: 'transactTime',
                          sorting: true,
                          filtering: false,
                      },
                  ]}
                  data={futureOrders}
                />
                {/* <Table size="small" aria-label="purchases">
                  <TableHead> 
                    <TableRow>
                      <TableCell align="right">Symbol</TableCell>
                      <TableCell align="right">Order Type</TableCell>
                      <TableCell align="right">Contract</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Cost</TableCell>
                      <TableCell align="right">Fees (%)</TableCell>
                      <TableCell align="right">Final Cost</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {futureOrders.map((futureOrder, index) => (
                      <TableRow key={`${index}`}>
                        <TableCell align="right">{futureOrder.symbol}</TableCell>
                        <TableCell align="right">{futureOrder.side}</TableCell>
                        <TableCell align="right">{futureOrder.origQty}</TableCell>
                        <TableCell align="right">{futureOrder.price}</TableCell>
                        <TableCell align="right">{futureOrder.cost}</TableCell>
                        <TableCell align="right">{futureOrder.fee}</TableCell>
                        <TableCell align="right">{futureOrder.finalCost}</TableCell>
                        <TableCell align="right">{dateFormat(futureOrder.transactTime, "mm/dd/yyyy h:MM:ss TT")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={addStream} onClose={() => this.setState({addStream: false})} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add Nano Long Assets, Short Future Position</DialogTitle>
          <DialogContent>
            <FormControl style={{width: '100%', marginBottom: 10}}>
              <InputLabel id="platform">Select Spot Market</InputLabel>
              <Select
                labelId="spotMarket"
                id="spotMarket"
                name="spotMarket"
                value={this.state.spotMarket}
                onChange={this.handleChange}
              >
                <MenuItem value={'Binance'}>Binance</MenuItem>
                <MenuItem value={'Kraken'}>Kraken</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{width: '100%', marginBottom: 10}}>
              <InputLabel id="platform">Select Future Market</InputLabel>
              <Select
                labelId="futureMarket"
                id="futureMarket"
                name="futureMarket"
                value={this.state.futureMarket}
                onChange={this.handleChange}
              >
                <MenuItem value={'Binance'}>Binance</MenuItem>
                <MenuItem value={'Kraken'}>Kraken</MenuItem>
                <MenuItem value={'Bitmax'}>Bitmax</MenuItem>
                <MenuItem value={'Deribit'}>Deribit</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{marginBottom: 10}}
              error={(!this.state.minEnterSpread && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              name="minEnterSpread"
              id="minEnterSpread"
              placeholder="9.999"
              label="Minimum Enter Spread"
              type="text"
              helperText={`Means Future Price is ${this.state.minEnterSpread ? (parseFloat(this.state.minEnterSpread) * 100).toFixed(3) : 0 * 100}% higer then Spot Price`}
              value={this.state.minEnterSpread}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              style={{marginBottom: 10}}
              error={(!this.state.minExitSpread && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="miniExitSpread"
              name="minExitSpread"
              placeholder="9.999"
              label="Minimum Exit Spread"
              type="text"
              hint
              value={this.state.minExitSpread}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              style={{marginBottom: 10}}
              error={(!this.state.spotCashLimit && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="spotCashLimit"
              name="spotCashLimit"
              label="Spot Cash Limit"
              type="text"
              value={this.state.spotCashLimit}
              onChange={this.handleChange}
              fullWidth
            />
            {/* <TextField
              style={{marginBottom: 10}}
              error={(!this.state.futureContractLimit && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="futureContractLimit"
              name="futureContractLimit"
              label={`Exit Future Contract Limit (x100 for ${this.state.platform})`}
              type="text"
              value={this.state.futureContractLimit}
              onChange={this.handleChange}
              fullWidth
              helperText={`In case Binance cannot exit: ${this.state.platform === 'Binance' ? (parseFloat(this.state.futureContractLimit) * 100) : this.state.futureContractLimit} USD worth of the contracts, or ${this.state.futureContractLimit} USD on other exchanges`}
            /> */}
            {/* <TextField
              style={{marginBottom: 10}}
              error={(!this.state.entrySpotLimit && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="entrySpotLimit"
              name="entrySpotLimit"
              label="Entry Spot Limit (USDT)"
              type="text"
              value={this.state.entrySpotLimit}
              onChange={this.handleChange}
              fullWidth
            /> */}
            <TextField
              style={{marginBottom: 10}}
              error={(!this.state.exitSpotLimit && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="exitSpotLimit"
              name="exitSpotLimit"
              placeholder="99.999"
              label="Exit Spot Limit (BTC, ETH)"
              type="text"
              value={this.state.exitSpotLimit}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              style={{marginBottom: 10}}
              error={(!this.state.entryContractLimit && this.state.isError) ? true : false}
              autoFocus
              margin="dense"
              id="entryContractLimit"
              name="entryContractLimit"
              placeholder="999,999"
              label="Entry Crypto Limit"
              type="text"
              value={this.state.entryContractLimit}
              onChange={this.handleChange}
              fullWidth
            />
            <FormControl style={{width: '100%', marginBottom: 10}}>
              <InputLabel id="coin">Coin</InputLabel>
              <Select
                labelId="coin"
                id="coin"
                name="coin"
                value={this.state.coin}
                onChange={this.handleChange}
              >
                <MenuItem value={'BTC'}>BTC</MenuItem>
                <MenuItem value={'ETH'}>ETH</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{width: '100%'}}>
              <InputLabel id="contract">Contract</InputLabel>
              <Select
                labelId="contract"
                id="contract"
                name="contract"
                value={this.state.contract}
                onChange={this.handleChange}
              >
                <MenuItem value={'0'}>Monthly</MenuItem>
                <MenuItem value={'1'}>Quaterly</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({addStream: false})} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.createStream()} color="primary">
              Add Stream
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
